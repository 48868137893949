import { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../components/Header/Header';
import { navigationUtils } from '../utils/navigationUtils';
import ActivateAccount from './ActivateAccount/ActivateAccount';
import AddCommerceFifthStep from './AddCommerce/AddCommerceFifthStep/AddCommerceFifthStep';
import AddCommerceFinalStep from './AddCommerce/AddCommerceFinalStep/AddCommerceFinalStep';
import AddCommerceFirstStep from './AddCommerce/AddCommerceFirstStep/AddCommerceFirstStep';
import AddCommerceFourthStep from './AddCommerce/AddCommerceFourthStep/AddCommerceFourthStep';
import AddCommerceSecondStep from './AddCommerce/AddCommerceSecondStep/AddCommerceSecondStep';
import AddCommerceSixthStep from './AddCommerce/AddCommerceSixthStep/AddCommerceSixthStep';
import AddCommerceThirdStep from './AddCommerce/AddCommerceThirdStep/AddCommerceThirdStep';
import Login from './Login/Login';
import ResetPassword from './ResetPassword/ResetPassword';
import RestorePassword from './RestorePassword/RestorePassword';
import Result from './Result/Result';
import PrivateRoute from './Routes/PrivateRoute';
import SearchCommerce from './SearchCommerce/SearchCommerce';

function Main() {
  const location = useLocation();
  const isPublicPage: boolean = navigationUtils.isPublicPage(location.pathname);
  const isLoggedIn = localStorage.getItem('fullName') ? true : false;

  useEffect(() => window.scrollTo({ top: 0 }), [location.pathname]);

  return (
    <div className="main">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
      {!isPublicPage && <Header />}
      <div className={isPublicPage ? 'public-content' : 'content'}>
        <Routes>
          <Route path="/activar-cuenta/:token" element={<ActivateAccount />} />
          <Route element={<PrivateRoute />}>
            <Route path="/buscar-comercio" element={<SearchCommerce />} />
            <Route path="/informacion-comercio" element={<AddCommerceFirstStep />} />
            <Route path="/sucursal" element={<AddCommerceSecondStep />} />
            <Route path="/beneficiario-final" element={<AddCommerceThirdStep />} />
            <Route path="/domicilio-cuenta" element={<AddCommerceFourthStep />} />
            <Route path="/rubro-tributo-terminal" element={<AddCommerceFifthStep />} />
            <Route path="/adjuntar-documentos" element={<AddCommerceSixthStep />} />
            <Route path="/confirmacion" element={<AddCommerceFinalStep />} />
          </Route>
          <Route path="/ingresar" element={<Login />} />
          <Route path="/recuperar-password/:token" element={<RestorePassword />} />
          <Route path="/recuperar-password" element={<ResetPassword />} />
          <Route path="/resultado" element={<Result />} />
          <Route path="*" element={<Navigate to={isLoggedIn ? '/buscar-comercio' : '/ingresar'} replace />} />
        </Routes>
      </div>
    </div>
  );
}

export default Main;
