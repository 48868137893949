import { ChangeEvent, ReactNode } from 'react';
import { Field, useField, useFormikContext } from 'formik';
import { Form } from 'react-bootstrap';
import clsx from 'clsx';

interface SelectProps {
  handleOnChange?: (
    targetValue: string,
    setFieldValue: (name: string, value: string | number | null) => void,
  ) => void;
  name: string;
  className?: string;
  children?: ReactNode;
  label?: string;
  required?: boolean;
  index?: number;
  isNumericValue?: boolean;
}

const Select = ({
  handleOnChange,
  children,
  name,
  className,
  label,
  required,
  index,
  isNumericValue,
}: SelectProps) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  return (
    <Form.Group controlId={index ? `${name}_${index}` : name} className={clsx('input', className)}>
      {label && (
        <Form.Label>
          {label}
          {required && <span className="required-asterisk"> *</span>}
        </Form.Label>
      )}
      <Field
        {...field}
        id={index ? `${name}_${index}` : name}
        value={field?.value?.id || field?.value}
        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
          handleOnChange
            ? handleOnChange(e.target.value, setFieldValue)
            : setFieldValue(name, isNumericValue && e.target.value !== '' ? +e.target.value : e.target.value)
        }
        as="select"
        className={clsx('input-div select', meta.error && meta.touched && 'input-error')}
      >
        <option value="">Seleccionar</option>
        {children}
      </Field>
      {meta.error && meta.touched && <span className="text-error form-text">{meta.error}</span>}
    </Form.Group>
  );
};

export default Select;
