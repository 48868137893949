import { Fragment, useEffect, useState, Dispatch, SetStateAction } from 'react';
import {
  businessPlansFields,
  basicInfoFields,
  mainAddressFields,
  bankAccountFields,
  tributaryInfoFields,
  economicActivityFields,
  terminalNetworkOptions,
  laws,
  banks,
} from '../../utils/data';
import {
  isEmptyField,
  updateDuplicatedBlockFields,
  updateBlockFields,
  getBeneficiaries,
  getEnumName,
  getEnumTypeValue,
  formatTableData,
  getTerminalTypeValue,
} from '../../utils/helpers';
import {
  BusinessTableChunk,
  NewBranchValues,
  NewBeneficiaryValues,
  FinalStepAttachments,
  NewTerminalValues,
  AddCommerceSixthAndFinalStepLocationState,
  InfoBlockField,
} from '../../types/add_commerce.types';
import { getBusinessPlansTableData } from '../../services/commerce.service';
import Attachments from './Attachments';
import BusinessTable from './BusinessTable';
import InfoBlock from './InfoBlock';

interface BusinessInfoProps {
  businessData: AddCommerceSixthAndFinalStepLocationState;
  showAttachments: boolean;
  setCheckboxState?: Dispatch<SetStateAction<boolean>>;
  checkboxState?: boolean;
  setAnticipatePayments?: Dispatch<SetStateAction<boolean>>;
  anticipatePayments?: boolean;
  comment?: string;
}

function BusinessInfo({
  businessData,
  showAttachments = false,
  setCheckboxState,
  checkboxState,
  setAnticipatePayments,
  anticipatePayments,
  comment,
}: BusinessInfoProps) {
  const [tableData, setTableData] = useState<Array<BusinessTableChunk>>([]);
  const [tableDataError, setTableDataError] = useState<string>('');

  const getTableData = async () => {
    const response = await getBusinessPlansTableData(
      getEnumTypeValue('taxPayer', businessData.taxpayerType!),
      getEnumTypeValue('businessCategory', businessData.businessCategory!),
    );

    if (response.result) {
      const formattedTableData = formatTableData(response.data);
      setTableData(formattedTableData);
    } else {
      setTableDataError(response?.data?.showMessage?.ES);
    }
  };

  useEffect(() => {
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const beneficiaries = getBeneficiaries(businessData.branches);

  const attachments: FinalStepAttachments = {
    bankStatement: businessData.bankStatement! as string,
    cardFormDGI: businessData.cardFormDGI! as string,
    beneficiaries,
    extraDocuments: [
      businessData.extraDocumentOne as string,
      businessData.extraDocumentTwo as string,
      businessData.extraDocumentThree as string,
    ],
  };

  const basicInfoValues = [
    businessData.rut,
    businessData.fantasyName,
    businessData.socialReason,
    `${businessData.contactFirstName} ${businessData.contactLastName}`,
    businessData.phone,
    businessData.cellphone,
    businessData.email,
    businessData.averageMonthlyBilling,
  ];

  const mainAddressValues = [
    businessData.neighborhood?.city?.state?.name,
    businessData.neighborhood?.city?.name,
    businessData.neighborhood?.name,
    `${businessData.addressStreet} ${businessData.addressNumber}`,
    isEmptyField(businessData.addressFlatNumber),
    isEmptyField(businessData.addressSolar),
    isEmptyField(businessData.addressPlot),
    businessData.averageMonthlyBilling,
  ];

  const bankAccountValues = [
    banks.find((bank) => bank.id === businessData.bankId)?.name,
    businessData.accountNumber,
    getEnumName('currency', String(businessData.accountCurrency)),
    businessData.accountBranch,
    businessData.accountHolder,
  ];

  const lawValues = [
    businessData.law_19210,
    businessData.law_17934,
    businessData.law_18083,
    businessData.law_18910,
  ];

  const basicInfoUpdated = updateBlockFields(basicInfoFields, basicInfoValues);
  const bankAccountUpdatedFields = updateBlockFields(bankAccountFields, bankAccountValues);
  const mainAddressUpdated = updateBlockFields(mainAddressFields, mainAddressValues);
  const tributaryInfoUpdated = updateBlockFields(tributaryInfoFields, businessData.taxpayerType?.description);
  const economicActivityUpdated = updateBlockFields(
    economicActivityFields,
    businessData.businessCategory?.name,
  );
  const lawsUpdated: InfoBlockField[] = updateBlockFields(
    laws.map((law) => law.name),
    lawValues,
  );
  const businessPlanUpdated = updateBlockFields(
    businessPlansFields,
    getEnumName('currency', String(businessData.accountCurrency)),
  );

  return (
    <>
      <InfoBlock title="Información básica del comercio" fields={basicInfoUpdated} />
      {businessData?.branches?.map((branch: NewBranchValues, index: number) => {
        const updatedFields = updateDuplicatedBlockFields('branch', branch);
        return (
          <InfoBlock
            title={`Sucursal ${index + 1}`}
            fields={updatedFields}
            key={index}
            isLastLoop={index === businessData?.branches.length - 1}
          />
        );
      })}
      {beneficiaries?.map((beneficiary: NewBeneficiaryValues, index: number) => {
        const updatedFields = updateDuplicatedBlockFields('beneficiary', beneficiary);
        return (
          <InfoBlock
            title={`Beneficiario final ${index + 1}`}
            fields={updatedFields}
            key={index}
            isLastLoop={index === beneficiaries.length - 1}
          />
        );
      })}
      <InfoBlock title="Domicilio principal" fields={mainAddressUpdated} />
      <InfoBlock title="Cuenta bancaria" fields={bankAccountUpdatedFields} />
      <InfoBlock title="Actividad económica" fields={economicActivityUpdated} />
      {/* <InfoBlock title="Operativa de venta" fields={salesOperationFields} /> */}
      <InfoBlock title="Información tributaria" fields={tributaryInfoUpdated}>
        <div className="confirmation-laws">
          {lawsUpdated?.map((law: InfoBlockField, index: number) =>
            law.value ? (
              <div key={index} className="confirmation-info info-fieldname semibold">
                {law.name}
              </div>
            ) : null,
          )}
        </div>
      </InfoBlock>
      <InfoBlock title="Red de terminales">
        {businessData?.terminals?.map((terminal: NewTerminalValues, index: number) => (
          <Fragment key={index}>
            <div className="info-fieldname semibold">
              {
                terminalNetworkOptions.find(
                  (option) => option.value === getTerminalTypeValue(terminal.type as string),
                )?.name
              }
            </div>
            <div className="confirmation-info">
              <div className="info-fieldname">Nº de Terminal</div>
              <div className="info">{isEmptyField(terminal.number)}</div>
            </div>
          </Fragment>
        ))}
      </InfoBlock>
      <InfoBlock title="Planes Comerciales" fields={businessPlanUpdated}>
        <div className="table-wrapper">
          <BusinessTable
            headings={['Plan', 'Moneda', 'Comisión', 'Diferido de']}
            tableData={tableData}
            tableDataError={tableDataError}
            setCheckboxState={setCheckboxState}
            checkboxState={checkboxState}
            setAnticipatePayments={setAnticipatePayments}
            anticipatePayments={anticipatePayments}
            comment={comment}
          />
        </div>
      </InfoBlock>
      {showAttachments && <Attachments attachments={attachments} />}
    </>
  );
}

export default BusinessInfo;
