import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { InputGroup, Form } from 'react-bootstrap';
import { COMMENT_MAX_LENGTH } from '../../utils/data';

interface BusinessCommentProps {
  setCommentFunction: Dispatch<SetStateAction<string>>;
  commentValue: string | null;
}

const BusinessCommentForm = ({ setCommentFunction, commentValue }: BusinessCommentProps) => {
  const commentChangeHandler = (event: ChangeEvent<HTMLInputElement>) =>
    setCommentFunction(event.target.value);

  return (
    <>
      <InputGroup>
        <InputGroup.Text className="comment-text">Agregue un comentario</InputGroup.Text>
        <Form.Control
          className="comment-input"
          as="textarea"
          onChange={commentChangeHandler}
          value={commentValue || ''}
          placeholder="Opcional - Escriba aquí el comentario"
          aria-label="With textarea"
        />
      </InputGroup>
      {commentValue?.length! > COMMENT_MAX_LENGTH ? (
        <p className="comment-info comment-error" style={{ color: 'red' }}>
          El comentario tiene más de {COMMENT_MAX_LENGTH} caracteres
        </p>
      ) : (
        <p className="comment-info">El comentario como máximo puede tener {COMMENT_MAX_LENGTH} caracteres</p>
      )}
    </>
  );
};

export default BusinessCommentForm;
