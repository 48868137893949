import * as Yup from 'yup';
import { banks, Bank } from './data';
import { updateSchemaWithBank } from './formikHelpers';

const requiredString = 'Campo requerido';
const requiredOption = 'Selecciona una opción';
const mailString = 'Formato de mail inválido';
const passwordLength = 8;
const rutLength = 12;
const rutLengthErrorString = `Solo acepta ${rutLength} digitos numéricos`;
const passwordLengthErrorString = `Tu contraseña debe tener al menos ${passwordLength} caracteres, una mayúscula, una minúscula y un número`;
const passwordNotEqual = 'La contraseña y la confirmación deben ser iguales.';
const phoneErrorString = 'Deben ser 9 numeros, iniciando con 0';
const numberOnlyErrorString = 'Sólo acepta números';
const negativeErrorString = 'Debe ser número positivo';
const documentNumberLengthErrorString = 'Debe tener 7 u 8 caracteres';
const addressAlphanumericErrorString = 'Debe contener al menos 1 número';

const numberSchemaForwardObjectRequired = Yup.number()
  .transform((_, val) => val?.id || Number(val))
  .positive(requiredString)
  .required(requiredString);

const numberSchemaOptionalNullable = Yup.number()
  .transform((_, val) => (val ? Number(val) : null))
  .positive(negativeErrorString)
  .nullable(true);

const numberSchemaUploadFile = Yup.number()
  .transform((_, val) => (val ? Number(val) : 0))
  .positive('Debe cargar un archivo');

const stringCelphoneNumberSchema = Yup.string()
  .matches(/^0{1}[0-9]{8}$/, phoneErrorString)
  .required(requiredString);

const requiredStringNullTransform = Yup.string()
  .transform((_, val) => {
    if (typeof val === 'number') return val.toString();
    return val ? val : '';
  })
  .required(requiredString);

const documentSchema = Yup.string()
  .min(7, documentNumberLengthErrorString)
  .max(8, documentNumberLengthErrorString)
  .required(requiredString);

const accountNumberInitialSchema = Yup.string()
  .ensure()
  .when('bankId', (bankId: any): any => {
    if (!bankId) return Yup.string().ensure().required('Debe seleccionar un banco');

    const bank: Bank = banks.find((bankData) => bankData.id === bankId)!;

    return updateSchemaWithBank(bank);
  })
  .required(requiredString);

const LoginSchema = Yup.object().shape({
  email: Yup.string().email(mailString).required(requiredString),
  password: Yup.string().required(requiredString),
});

const ActivationSchema = Yup.object().shape({
  document: Yup.number().typeError(requiredString).required(requiredString),
  password: Yup.string()
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, passwordLengthErrorString)
    .required(requiredString),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], passwordNotEqual)
    .required(requiredString),
});

const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string().email(mailString).required(requiredString),
});

const SearchCommerceSchema = Yup.object().shape({
  rut: Yup.string()
    .matches(/^[0-9]{12}$/, rutLengthErrorString)
    .ensure()
    .required(requiredString),
});

const ActivateCommerceFirstStep = Yup.object().shape({
  /*   rut: Yup.number().typeError(requiredString).required(requiredString), */
  fantasyName: Yup.string().required(requiredString),
  socialReason: Yup.string().required(requiredString),
  contactFirstName: Yup.string().required(requiredString),
  contactLastName: Yup.string().required(requiredString),
  phone: Yup.string()
    .matches(/^\d{1,}$/, numberOnlyErrorString)
    .required(requiredString),
  cellphone: stringCelphoneNumberSchema,
  email: Yup.string().email(mailString).required(requiredString),
  averageMonthlyBilling: numberSchemaOptionalNullable,
});

const ActivateCommerceSecondStep = Yup.object().shape({
  fantasyName: Yup.string().required(requiredString),
  state: numberSchemaForwardObjectRequired,
  city: numberSchemaForwardObjectRequired,
  neighborhood: numberSchemaForwardObjectRequired,
  addressStreet: Yup.string().required(requiredString),
  addressNumber: Yup.string()
    .matches(/(\d){1}/, addressAlphanumericErrorString)
    .required(requiredString),
  addressFlatNumber: numberSchemaOptionalNullable,
  addressSolar: numberSchemaOptionalNullable,
  addressPlot: numberSchemaOptionalNullable,
});

const ActivateCommerceThirdStep = Yup.object().shape({
  documentType: Yup.number().typeError(requiredOption).required(requiredString),
  documentNumber: documentSchema,
  firstName: Yup.string().required(requiredString),
  lastName: Yup.string().required(requiredString),
  participationPercentage: Yup.number()
    .typeError('El % de participación es un campo numérico')
    .required(requiredString)
    .positive(negativeErrorString)
    .max(100, '100% es el máximo'),
  isPep: Yup.boolean().required(requiredString),
  isRelative: Yup.boolean().required(requiredString),
  branchOfficeId: Yup.number().typeError(requiredOption).required(requiredString),
});

const ActivateCommerceThirdStepFamiliarPep = Yup.object().shape({
  relativeDocumentType: Yup.number().typeError(requiredOption).required(requiredString),
  relativeDocumentNumber: documentSchema,
  relativeFirstName: Yup.string().required(requiredString),
  relativeLastName: Yup.string().required(requiredString),
});

const ActivateCommerceFourthStep = Yup.object().shape({
  addressState: numberSchemaForwardObjectRequired,
  addressCity: numberSchemaForwardObjectRequired,
  addressNeighborhood: numberSchemaForwardObjectRequired,
  addressStreet: requiredStringNullTransform,
  addressNumber: Yup.string()
    .matches(/(\d){1}/, addressAlphanumericErrorString)
    .required(requiredString),
  addressFlatNumber: numberSchemaOptionalNullable,
  addressSolar: numberSchemaOptionalNullable,
  addressPlot: numberSchemaOptionalNullable,
  bankId: requiredStringNullTransform,
  accountNumber: accountNumberInitialSchema,
  accountCurrency: requiredStringNullTransform,
  accountBranch: Yup.number().positive(negativeErrorString),
  accountHolder: Yup.string().required(requiredString),
  accountType: Yup.string().required(requiredString),
});

const ActivateCommerceFifthStep = Yup.object().shape({
  businessCategoryId: Yup.string().ensure().required(requiredString),
  taxpayerTypeId: Yup.string().ensure().required(requiredString),
  law_19210: Yup.boolean(),
  law_17934: Yup.boolean(),
  law_18083: Yup.boolean(),
  law_18910: Yup.boolean(),
});

const ActivateCommerceFifthStepTerminals = Yup.object().shape({
  type: Yup.string().required(requiredString),
  number: Yup.string().nullable(),
});

const ActivateCommerceSixthStepFirst = Yup.object().shape({
  cardFormDGI: numberSchemaUploadFile,
  bankStatement: numberSchemaUploadFile,
});

const ActivateCommerceSixthStepIds = Yup.object().shape({
  frontDocument: numberSchemaUploadFile,
  backDocument: numberSchemaUploadFile,
});

const schemas = {
  ResetPasswordSchema,
  LoginSchema,
  SearchCommerceSchema,
  ActivationSchema,
  ActivateCommerceFirstStep,
  ActivateCommerceSecondStep,
  ActivateCommerceThirdStep,
  ActivateCommerceThirdStepFamiliarPep,
  ActivateCommerceFourthStep,
  ActivateCommerceFifthStep,
  ActivateCommerceFifthStepTerminals,
  ActivateCommerceSixthStepFirst,
  ActivateCommerceSixthStepIds,
  numberOnlyErrorString,
};

export default schemas;
