import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Container, ProgressBar, Row } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import BusinessLogo from '../../../assets/business.png';
import NavigationFooter from '../../../components/NavigationFooter/NavigationFooter';
import { updatePageTwo, deleteBusinessBranch } from '../../../services/commerce.service';
import { triggerToasts } from '../../../utils/helpers';
import { doRefsSubmissions } from '../../../utils/formikHelpers';
import { newBranch } from '../../../utils/data';
import { OfficeBranch } from '../../../types/business/request';
import {
  NewBranchValues,
  AddCommerceSecondStepLocationState,
  State,
  City,
  Neighborhood,
} from '../../../types/add_commerce.types';
import NewBranch from './NewBranch';

function AddCommerceSecondStep() {
  const location = useLocation();
  const navigate = useNavigate();
  const formRefs = useRef<any>([]);
  const localState: AddCommerceSecondStepLocationState = location.state as AddCommerceSecondStepLocationState;

  const [branches, setBranches] = useState<Array<NewBranchValues>>(
    localState.branches?.length ? localState.branches : [{ ...newBranch, localId: uuidv4() }],
  );
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [submittedBranches, setSubmittedBranches] = useState<Array<NewBranchValues>>([]);

  const submitBranch = (
    values: NewBranchValues,
    index: number,
    state: State,
    city: City,
    neighborhood: Neighborhood,
  ) => {
    const updateArray = [...submittedBranches];
    updateArray[index] = { ...values, state, city, neighborhood };
    setSubmittedBranches(updateArray);
  };

  const postBranches = async () => {
    const officesBranch: OfficeBranch[] = submittedBranches?.map((branch) => ({
      fantasyName: branch.fantasyName,
      neighborhood: branch.neighborhood?.id.toString()!,
      addressStreet: branch.addressStreet,
      addressNumber: branch.addressNumber?.toString()!,
      addressFlatNumber: branch.addressFlatNumber?.toString()! || '',
      addressSolar: branch.addressSolar?.toString()! || '',
      addressPlot: branch.addressPlot?.toString()! || '',
    }));

    const response = await updatePageTwo(localState.id!, { officesBranch });

    if (response.result && response.data) {
      const businessData = response.data.businessData || response.data;
      return navigate('/beneficiario-final', { state: { ...localState, ...businessData } });
    }
    triggerToasts(response);
  };

  useEffect(() => {
    if (submitted && submittedBranches.length === branches.length) {
      setSubmitted(false);
      postBranches();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branches, submittedBranches, localState, navigate]);

  const handleGoBack = () => navigate('/informacion-comercio', { state: { ...localState } });

  const handleSubmit = () => {
    if (formRefs.current) {
      const didFormRefsSubmitted: boolean = doRefsSubmissions(formRefs);
      didFormRefsSubmitted && setSubmitted(true);
    }
  };

  const handleAddNewBranch = () => setBranches([...branches, { ...newBranch, localId: uuidv4() }]);

  const handleDeleteBranch = async (index: number) => {
    const branchToDelete = branches.find(
      (branch: NewBranchValues, filterIndex: number) => index === filterIndex,
    );
    if (branchToDelete?.id) {
      const response = await deleteBusinessBranch(localState.id!, branchToDelete?.id);

      if (response.result) {
        return setBranches(response.data.branches);
      }
      return triggerToasts(response);
    }

    const branchesFiltered = branches.filter(
      (branch: NewBranchValues, filterIndex: number) => index !== filterIndex,
    );

    setBranches(branchesFiltered);
  };

  return (
    <>
      <Container className="second-step">
        <ProgressBar now={(100 / 7) * 2} />
        <Row>
          <Col lg={12}>
            <h1>
              <img src={BusinessLogo} alt="Comercio" className="me-3" /> Sucursales
            </h1>
            {branches.map((branch: NewBranchValues, index: number) => (
              <NewBranch
                innerRef={(formElement: any) => (formRefs.current[index] = formElement)}
                submitBranch={submitBranch}
                key={branch.localId || `${branch.fantasyName}_${index}`}
                index={index}
                branchData={branch}
                handleDelete={handleDeleteBranch}
              />
            ))}
          </Col>
        </Row>
        <div>
          <Button onClick={handleAddNewBranch} className="add-section">
            <FontAwesomeIcon icon={faPlus} />
            Agregar sucursal
          </Button>
        </div>
      </Container>

      <NavigationFooter previousHandler={handleGoBack} nextHandler={handleSubmit} />
    </>
  );
}

export default AddCommerceSecondStep;
