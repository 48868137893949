import { Formik } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import Input from '../../../components/Input/Input';
import Select from '../../../components/Input/Select';
import schemas from '../../../utils/schemas';
import { terminalNetworkOptions } from '../../../utils/data';
import { getTerminalTypeValue } from '../../../utils/helpers';
import { NewTerminalValues, InfoBlockField } from '../../../types/add_commerce.types';

interface NewTerminalProps {
  innerRef: any;
  submitTerminal(values: NewTerminalValues, index: number): void;
  index: number;
  terminalData: NewTerminalValues;
  handleDelete: (index: number) => void;
}

function NewTerminal({ innerRef, submitTerminal, index, terminalData, handleDelete }: NewTerminalProps) {
  return (
    <Formik
      innerRef={innerRef}
      initialValues={{
        type: getTerminalTypeValue(terminalData?.type as string),
        number: terminalData?.number!,
      }}
      enableReinitialize
      validationSchema={schemas.ActivateCommerceFifthStepTerminals}
      onSubmit={(values: NewTerminalValues) => submitTerminal(values, index)}
    >
      <Form>
        <Row>
          <Col sm={11}>
            <Row>
              <Select className="col-md-6" name="type" label="Terminal" required index={index}>
                {terminalNetworkOptions.map((terminal: InfoBlockField) => (
                  <option key={terminal.value} value={terminal.value}>
                    {terminal.name}
                  </option>
                ))}
              </Select>
              <Input
                className="input col-md-6"
                type="text"
                name="number"
                label="Nº de terminal (opcional)"
                index={index}
              />
            </Row>
          </Col>
          <Col className="terminal-trash">
            {index > 0 && (
              <FontAwesomeIcon className="faTrashCan" icon={faTrashCan} onClick={() => handleDelete(index)} />
            )}
          </Col>
        </Row>
      </Form>
    </Formik>
  );
}

export default NewTerminal;
