import { Dispatch, SetStateAction, ChangeEvent } from 'react';

interface PaymentsOptionsProps {
  setCheckboxState?: Dispatch<SetStateAction<boolean>>;
  checkboxState?: boolean;
  setAnticipatePayments?: Dispatch<SetStateAction<boolean>>;
  anticipatePayments?: boolean;
  comment?: string;
}

function PaymentsOptions({
  setCheckboxState,
  checkboxState,
  setAnticipatePayments,
  anticipatePayments,
  comment,
}: PaymentsOptionsProps) {
  return (
    <>
      <div className="fee-checkbox-container">
        <input
          type="checkbox"
          className="fee-checkbox"
          disabled={setCheckboxState ? false : true}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setCheckboxState!(e.target.checked)}
          checked={checkboxState}
        />
        <span className="fee-checkbox-label">
          Cuotas con recargo
          {/* 
          TODO: Add coeficents link
          <a href="TODO" target="_blank" rel="noreferrer">
            Ver coeficientes
          </a>
          */}
        </span>
      </div>
      <div className="fee-checkbox-container">
        <input
          type="checkbox"
          className="fee-checkbox"
          disabled={setAnticipatePayments ? false : true}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setAnticipatePayments!(e.target.checked)}
          checked={anticipatePayments}
        />
        <span className="fee-checkbox-label">Solicito anticipar todos mis pagos a 24hs. TEA: 15%</span>
      </div>
      <br />
      {comment && (
        <div className="comment-showcase-container">
          <p className="comment-showcase">
            Comentario: <span className="comment">{comment}</span>
          </p>
        </div>
      )}
    </>
  );
}

export default PaymentsOptions;
