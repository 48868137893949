import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import CardHeader from 'react-bootstrap/esm/CardHeader';
import ReCAPTCHA from 'react-google-recaptcha';
import { Formik, Form } from 'formik';
import logo from '../../assets/logo-getnet-full@2x.png';
import Input from '../../components/Input/Input';
import { resetPassword } from '../../services/user.service';
import schemas from '../../utils/schemas';
import { captchaConfig } from '../../utils/captchaConfig';

interface ResetPasswordValues {
  email: string;
}

function ResetPassword() {
  const navigate = useNavigate();
  const [resetPasswordErrorMessage, setResetPasswordErrorMessage] = useState<string>('');
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const submitResetPassword = async (values: ResetPasswordValues) => {
    if (captchaToken) {
      setIsLoading(true);
      const resetPasswordResponse = await resetPassword(values.email);

      if (resetPasswordResponse.result) {
        navigate('/resultado', {
          state: {
            email: values.email,
            response: resetPasswordResponse.result,
            isFromRestorePasswordEmailSender: true,
          },
        });
      } else {
        const resetPasswordErrorMessageES = resetPasswordResponse.showMessage.ES;
        setResetPasswordErrorMessage(resetPasswordErrorMessageES);
      }
      setIsLoading(false);
    } else {
      setResetPasswordErrorMessage('Debes completar el captcha');
    }
  };

  const captchaHandler = (token: string | null) => {
    setCaptchaToken(token);
    setResetPasswordErrorMessage('');
  };

  return (
    <Container fluid>
      <Row>
        <Col md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }} xl={{ span: 4, offset: 4 }}>
          <Card className="card">
            <CardHeader>
              <img src={logo} alt="Getnet" style={{ width: 152 }} />
              <h1>Onboarding Ejecutivos</h1>
            </CardHeader>
            <Card.Body>
              <p>Ingresar al sistema</p>
              <p>Ingresa tu dirección de email, te enviaremos un link para resetear tu contraseña</p>
              <Formik
                onSubmit={submitResetPassword}
                initialValues={{ email: '' }}
                validationSchema={schemas.ResetPasswordSchema}
              >
                <Form>
                  <Input className="position-relative pb-3" type="email" name="email" placeholder="Email" />
                  <div className="captcha-container">
                    <ReCAPTCHA onChange={captchaHandler} sitekey={captchaConfig.webkey} />
                  </div>
                  {!!resetPasswordErrorMessage && (
                    <p className="reset-password-error">{resetPasswordErrorMessage}</p>
                  )}
                  <Button type="submit" className="primary-outlined-button w-100" disabled={isLoading}>
                    {isLoading ? 'Cargando...' : 'Enviar'}
                  </Button>
                </Form>
              </Formik>
            </Card.Body>
            <Link to="/ingresar" className="back-to-login">
              Volver a ingresar
            </Link>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ResetPassword;
