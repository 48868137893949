import { Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, ProgressBar, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import activeBankLogo from '../../../assets/icon-bank-active.svg';
import activeCommerceLogo from '../../../assets/icon-empresa-active.png';
import Input from '../../../components/Input/Input';
import Select from '../../../components/Input/Select';
import NavigationFooter from '../../../components/NavigationFooter/NavigationFooter';
import { getCities, getNeighborhoods, getStates, updatePageFour } from '../../../services/commerce.service';
import {
  AddCommerceFourthStepLocationState,
  AddCommerceFourthStepValues,
  City,
  InfoBlockField,
  Neighborhood,
  NewBranchValues,
  State,
} from '../../../types/add_commerce.types';
import { UpdateBusinessAccountAddressRequest } from '../../../types/business/request';
import { CurrencyEnum } from '../../../types/enums/currency.enum';
import { AccountType, Bank, bankAccountTypes, banks, currencies } from '../../../utils/data';
import { triggerToasts } from '../../../utils/helpers';
import schemas from '../../../utils/schemas';
import ConfirmBankModal from './ConfirmBankModal';

function AddCommerceFourthStep() {
  const location = useLocation();
  const navigate = useNavigate();
  const formRef = useRef<any>();
  const localState = location.state as AddCommerceFourthStepLocationState;
  const [states, setStates] = useState<Array<State>>([]);
  const [cities, setCities] = useState<Array<City>>([]);
  const [neighborhoods, setNeighborhoods] = useState<Array<Neighborhood>>([]);
  const [chosenState, setChosenState] = useState<State | null>(localState?.neighborhood?.city?.state || null);
  const [chosenCity, setChosenCity] = useState<City | null>(localState?.neighborhood?.city || null);
  const [showModal, setShowModal] = useState(false);

  const fetchStates = async () => {
    const responseStates = await getStates();
    setStates(responseStates);
  };

  const fetchCities = async (state: State) => {
    const responseCities = await getCities(state.id);
    setCities(responseCities.data);
  };

  const fetchNeighborhoods = async (city: City) => {
    const responseNeighborhoods = await getNeighborhoods(city.id);
    setNeighborhoods(responseNeighborhoods.data);
  };

  const handleOnChangeState = (
    targetValue: string,
    setFieldValue: (name: string, value: string | number) => void,
  ) => {
    setFieldValue('addressState', +targetValue);
    setFieldValue('addressCity', '');
    setFieldValue('addressNeighborhood', '');
    const chosenState = states.find((state: State) => state.id === +targetValue);
    setChosenState(chosenState || null);
    setChosenCity(null);
  };

  const handleOnChangeCity = (
    targetValue: string,
    setFieldValue: (name: string, value: string | number) => void,
  ) => {
    setFieldValue('addressCity', +targetValue);
    setFieldValue('addressNeighborhood', '');
    const chosenCity = cities.find((city: City) => city.id === +targetValue);
    setChosenCity(chosenCity || null);
  };

  const handleOnChangeNeighborhood = (
    targetValue: string,
    setFieldValue: (name: string, value: string | number) => void,
  ) => {
    setFieldValue('addressNeighborhood', +targetValue);
  };

  useEffect(() => {
    fetchStates();
    localState?.addressState && fetchCities(localState?.addressState as State);
    localState?.addressCity && fetchNeighborhoods(localState?.addressCity as City);
  }, [localState?.addressState, localState?.addressCity]);

  useEffect(() => {
    chosenState !== null && fetchCities(chosenState);
  }, [chosenState]);

  useEffect(() => {
    chosenCity !== null && fetchNeighborhoods(chosenCity);
  }, [chosenCity]);

  const handleGoBack = () => navigate('/beneficiario-final', { state: { ...localState } });

  const handleNextStep = async (values: AddCommerceFourthStepValues) => {
    const businessInfo: UpdateBusinessAccountAddressRequest = {
      neighborhoodId: +values.addressNeighborhood! || +values.addressNeighborhood?.id!,
      addressStreet: values.addressStreet,
      addressNumber: values.addressNumber,
      addressFlatNumber: values.addressFlatNumber,
      addressSolar: values.addressSolar,
      addressPlot: values.addressPlot,
      bankId: values.bankId,
      accountCurrency: String(values.accountCurrency),
      accountNumber: values.accountNumber,
      accountHolder: values.accountHolder,
      accountBranch: values.accountBranch || '',
      accountType: values.accountType,
    };

    const response = await updatePageFour(localState.id!, businessInfo);

    if (response.result && response.data) {
      const businessData = response.data.businessData || response.data;
      return navigate('/rubro-tributo-terminal', { state: { ...localState, ...businessData } });
    }
    triggerToasts(response);
  };

  const toggleModal = () => setShowModal(!showModal);

  const handleSubmit = () => formRef?.current?.handleSubmit();

  const handleFillWithBranchData = async (setFieldValue: (name: string, value: string | number) => void) => {
    const branch: NewBranchValues = localState.branches[0];

    handleOnChangeState(String(branch.neighborhood?.city?.stateId!), setFieldValue);
    handleOnChangeCity(String(branch.neighborhood?.cityId!), setFieldValue);
    setFieldValue('addressStreet', branch.addressStreet);
    setFieldValue('addressNumber', branch.addressNumber!);
    setFieldValue('addressFlatNumber', branch.addressFlatNumber || '');
    setFieldValue('addressSolar', branch.addressSolar || '');
    setFieldValue('addressPlot', branch.addressPlot || '');

    await fetchNeighborhoods(branch.neighborhood?.city!);

    handleOnChangeNeighborhood(String(branch.neighborhood?.id!), setFieldValue);
  };

  return (
    <>
      <Container className="fourth-step">
        <ProgressBar now={(100 / 7) * 4} />
        <Row>
          <Col lg={12}>
            <Formik
              innerRef={(formElement: any) => (formRef.current = formElement)}
              initialValues={{
                addressState: localState?.neighborhood?.city?.state!,
                addressCity: localState?.neighborhood?.city!,
                addressNeighborhood: localState?.neighborhood!,
                addressStreet: localState?.addressStreet!,
                addressNumber: localState?.addressNumber || '',
                addressFlatNumber: localState?.addressFlatNumber!,
                addressSolar: localState?.addressSolar!,
                addressPlot: localState?.addressPlot!,
                bankId: localState?.bankId!,
                accountNumber: localState?.accountNumber!,
                accountCurrency: CurrencyEnum[localState?.accountCurrency as keyof typeof CurrencyEnum] || '',
                accountBranch: localState?.accountBranch || '',
                accountHolder: localState?.accountHolder || '',
                accountType: localState?.accountType || '',
              }}
              validationSchema={schemas.ActivateCommerceFourthStep}
              onSubmit={toggleModal}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <Row>
                    <div className="div-container">
                      <h1 className="row-title-space">
                        <img src={activeCommerceLogo} alt="Comercio" className="me-3" /> Domicilio legal
                      </h1>
                      <Button
                        variant="link"
                        className="w-10 branch-button"
                        onClick={() => handleFillWithBranchData(setFieldValue)}
                      >
                        Igual que sucursal
                      </Button>
                    </div>
                    <Select
                      className="col-md-6"
                      name="addressState"
                      handleOnChange={handleOnChangeState}
                      label="Departamento"
                      required
                    >
                      {states.map((state: State, index: number) => (
                        <option key={index} value={state.id}>
                          {state.name}
                        </option>
                      ))}
                    </Select>
                    <Select
                      className="col-md-6"
                      name="addressCity"
                      handleOnChange={handleOnChangeCity}
                      label="Localidad"
                      required
                    >
                      {cities.map((city: City, index: number) => (
                        <option key={index} value={city.id}>
                          {city.name}
                        </option>
                      ))}
                    </Select>
                    <Select
                      className="col-md-6"
                      name="addressNeighborhood"
                      handleOnChange={handleOnChangeNeighborhood}
                      label="Barrio"
                      required
                    >
                      {neighborhoods.map((neighborhood: Neighborhood, index: number) => (
                        <option key={index} value={neighborhood.id}>
                          {neighborhood.name}
                        </option>
                      ))}
                    </Select>
                    <Input
                      className="input col-md-6"
                      type="text"
                      name="addressStreet"
                      label="Calle"
                      required
                    />
                    <Input
                      className="input col-md-3"
                      type="text"
                      name="addressNumber"
                      label="N° de puerta"
                      required
                    />
                    <Input
                      className="input col-md-3"
                      type="number"
                      name="addressFlatNumber"
                      label="Apto (Opcional)"
                    />
                    <Input
                      className="input col-md-3"
                      type="number"
                      name="addressSolar"
                      label="Solar (Opcional)"
                    />
                    <Input
                      className="input col-md-3"
                      type="number"
                      name="addressPlot"
                      label="Manzana (Opcional)"
                    />
                  </Row>
                  <Row>
                    <h1 className="row-title-space">
                      <img src={activeBankLogo} alt="Comercio" className="me-3" /> Cuenta bancaria
                    </h1>
                    <Select className="col-md-6" name="bankId" label="Banco" required>
                      {banks.map((bank: Bank, index: number) => (
                        <option key={index} value={bank.id}>
                          {bank.name}
                        </option>
                      ))}
                    </Select>
                    <Input
                      className="input col-md-6"
                      type="text"
                      name="accountNumber"
                      label="N° de cuenta"
                      required
                      disabled={!values.bankId}
                    />
                    <Select className="col-md-6" name="accountCurrency" label="Moneda" required>
                      {currencies.map((currency: InfoBlockField, index: number) => (
                        <option key={index} value={currency.value}>
                          {currency.name}
                        </option>
                      ))}
                    </Select>
                    <Input
                      className="input col-md-6"
                      type="number"
                      name="accountBranch"
                      label="N° de sucursal (Opcional)"
                    />
                    <Input
                      className="input col-md-6"
                      type="text"
                      name="accountHolder"
                      label="Titular de la cuenta"
                      required
                    />
                    <Select className="col-md-6" name="accountType" label="Tipo de cuenta" required>
                      {bankAccountTypes.map((accountType: AccountType, index: number) => (
                        <option key={index} value={accountType.value}>
                          {accountType.name}
                        </option>
                      ))}
                    </Select>
                  </Row>
                  <ConfirmBankModal
                    show={showModal}
                    toggleModal={toggleModal}
                    handleNextStep={handleNextStep}
                    values={values}
                  />
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
      <NavigationFooter previousHandler={handleGoBack} nextHandler={handleSubmit} />
    </>
  );
}

export default AddCommerceFourthStep;
