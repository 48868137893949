import { ChangeEvent, useState, useEffect, useRef, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Col, Container, ProgressBar, Row } from 'react-bootstrap';
import { getBeneficiaries, triggerToasts } from '../../../utils/helpers';
import pspLogo from '../../../assets/PSP.svg';
import schemas from '../../../utils/schemas';
import { doRefsSubmissions } from '../../../utils/formikHelpers';
import FileInput from '../../../components/Input/FileInput';
import NavigationFooter from '../../../components/NavigationFooter/NavigationFooter';
import { updatePageSix } from '../../../services/commerce.service';
import { UpdateFilesRequest } from '../../../types/business/request';
import {
  AddCommerceSixthAndFinalStepLocationState,
  BeneficiaryId,
  NewBeneficiaryValues,
} from '../../../types/add_commerce.types';
import NewBeneficiaryId from './NewBeneficiaryId';

function AddCommerceSixthStep() {
  const location = useLocation();
  const navigate = useNavigate();
  const localState = location.state as AddCommerceSixthAndFinalStepLocationState;
  const formRefs = useRef<any>([]);
  const [cardFormDGI, setCardFormDGI] = useState<File | string | null>(localState.cardFormDGI || null);
  const [bankStatement, setBankStatement] = useState<File | string | null>(localState.bankStatement || null);
  const [extraDocumentOne, setExtraDocumentOne] = useState<File | string | null>(
    localState.extraDocumentOne || null,
  );
  const [extraDocumentTwo, setExtraDocumentTwo] = useState<File | string | null>(
    localState.extraDocumentTwo || null,
  );
  const [extraDocumentThree, setExtraDocumentThree] = useState<File | string | null>(
    localState.extraDocumentThree || null,
  );
  const beneficiaries = useMemo(() => getBeneficiaries(localState.branches), [localState.branches]);
  const [beneficiaryIds, setBeneficiaryIds] = useState<Array<BeneficiaryId>>(
    beneficiaries.map((beneficiary: NewBeneficiaryValues) => ({
      id: beneficiary.id!,
      frontDocument: beneficiary.frontDocument!,
      backDocument: beneficiary.backDocument!,
    })),
  );
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const postFiles = async () => {
    const fileBody: UpdateFilesRequest = {
      rut: cardFormDGI!,
      cardAccountStatus: bankStatement!,
      beneficiaryIds: beneficiaryIds?.map((beneficiaryData: BeneficiaryId) => ({
        beneficiaryId: beneficiaryData.id,
        idFront: beneficiaryData?.frontDocument!,
        idBack: beneficiaryData?.backDocument!,
      })),
      extraDocumentOne,
      extraDocumentTwo,
      extraDocumentThree,
    };

    const response = await updatePageSix(localState.id!, fileBody);

    setIsSubmitting(false);

    if (response.result && response.data) {
      const businessData = response.data.businessData || response.data;
      return navigate('/confirmacion', { state: { ...localState, ...businessData } });
    }

    triggerToasts(response);
  };

  useEffect(() => {
    if (
      isSubmitting &&
      cardFormDGI &&
      beneficiaryIds.filter((idFiles) => idFiles.frontDocument && idFiles.backDocument).length ===
        beneficiaries.length
    ) {
      postFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beneficiaryIds, cardFormDGI, bankStatement, localState, navigate]);

  const handleGoBack = () => navigate('/rubro-tributo-terminal', { state: { ...localState } });

  const handleSubmit = () => {
    if (formRefs.current) {
      const didFormRefsSubmitted: boolean = doRefsSubmissions(formRefs);
      didFormRefsSubmitted && setIsSubmitting(true);
    }
  };

  const handleFormsSubmit = () => {};

  const handleBeneficiaryIdsSubmit = (
    frontDocument: File | string | null,
    backDocument: File | string | null,
    index: number,
  ) => {
    const replaceArray = beneficiaryIds?.map((beneficiaryData: BeneficiaryId, mapIndex: number) =>
      mapIndex === index ? { ...beneficiaryData, frontDocument, backDocument } : beneficiaryData,
    );
    setBeneficiaryIds(replaceArray);
  };

  const handleFile = (
    e: ChangeEvent<HTMLInputElement>,
    name: string,
    setFieldValue: (name: string, value: number) => void,
  ) => {
    if (e?.target?.files) {
      setFieldValue(name, e.target.files.length);
      name === 'cardFormDGI' && setCardFormDGI(e.target.files[0]);
      name === 'bankStatement' && setBankStatement(e.target.files[0]);
      name === 'extraDocumentOne' && setExtraDocumentOne(e.target.files[0]);
      name === 'extraDocumentTwo' && setExtraDocumentTwo(e.target.files[0]);
      name === 'extraDocumentThree' && setExtraDocumentThree(e.target.files[0]);
    }
  };

  return (
    <>
      <Container className="sixth-step">
        <ProgressBar now={(100 / 7) * 6} />
        <Row>
          <Col lg={12}>
            <Formik
              innerRef={(formElement: any) => (formRefs.current[0] = formElement)}
              initialValues={{
                cardFormDGI: localState.cardFormDGI ? 1 : null,
                bankStatement: localState.bankStatement ? 1 : null,
              }}
              validationSchema={schemas.ActivateCommerceSixthStepFirst}
              onSubmit={handleFormsSubmit}
            >
              <Form>
                <h1 className="row-title-space">
                  <img src={pspLogo} alt="Comercio" className="me-3" /> Adjuntar documentos
                </h1>
                <br />
                <label className="input-title" htmlFor="cardFormDGI">
                  Tarjeta de RUT o Formulario DGI 6361 - 6161 - 6351:{' '}
                  <span className="required-asterisk">*</span>
                </label>
                <FileInput name="cardFormDGI" file={cardFormDGI} setFile={handleFile} />
                <label className="input-title" htmlFor="bankStatement">
                  Estado de cuenta de tarjeta:
                  <span className="required-asterisk">*</span>
                </label>
                <FileInput name="bankStatement" file={bankStatement} setFile={handleFile} />
              </Form>
            </Formik>
            {beneficiaries?.map((beneficiary: NewBeneficiaryValues, index: number) => (
              <NewBeneficiaryId
                key={index}
                innerRef={(formElement: any) => (formRefs.current[index + 1] = formElement)}
                beneficiary={beneficiary}
                index={index}
                handleSubmit={handleBeneficiaryIdsSubmit}
              />
            ))}
            <Formik
              innerRef={(formElement: any) => (formRefs.current[0] = formElement)}
              initialValues={{
                extraDocumentOne: localState.extraDocumentOne ? 1 : null,
                extraDocumentTwo: localState.extraDocumentTwo ? 1 : null,
                extraDocumentThree: localState.extraDocumentThree ? 1 : null,
              }}
              validationSchema={schemas.ActivateCommerceSixthStepFirst}
              onSubmit={handleFormsSubmit}
            >
              <Form className="extra-documents">
                <label className="input-title extra-document-input" htmlFor="extraDocumentOne">
                  Documento extra 1:
                </label>
                <FileInput name="extraDocumentOne" file={extraDocumentOne} setFile={handleFile} />
                <label className="input-title extra-document-input" htmlFor="extraDocumentTwo">
                  Documento extra 2:
                </label>
                <FileInput name="extraDocumentTwo" file={extraDocumentTwo} setFile={handleFile} />
                <label className="input-title extra-document-input" htmlFor="extraDocumentThree">
                  Documento extra 3:
                </label>
                <FileInput name="extraDocumentThree" file={extraDocumentThree} setFile={handleFile} />
              </Form>
            </Formik>
          </Col>
        </Row>
      </Container>
      <NavigationFooter previousHandler={handleGoBack} nextHandler={handleSubmit} loading={isSubmitting} />
    </>
  );
}

export default AddCommerceSixthStep;
