import {
  InfoBlockField,
  NewBeneficiaryValues,
  NewBranchValues,
  NewTerminalValues,
} from '../types/add_commerce.types';
import { CurrencyEnum } from '../types/enums/currency.enum';
import { DocumentTypeEnum } from '../types/enums/document-type.enum';
import { TerminalTypesEnum } from '../types/enums/terminal-types.enum';
import { LawsEnum } from '../types/enums/law.enum';
import { BankAccountTypesEnum } from '../types/enums/bank-account-types.enum';

export interface Bank {
  id: string;
  name: string;
  lengths: number | number[] | null;
}

export interface LawFormField {
  label: string;
  fieldname: string;
  value: LawsEnum;
}

export interface AccountType {
  name: string;
  value: BankAccountTypesEnum;
}

export const banks: Bank[] = [
  { id: '50', name: 'BROU', lengths: 14 },
  { id: '51', name: 'CITIBANK', lengths: 10 },
  { id: '52', name: 'SCOTIABANK', lengths: 10 },
  { id: '53', name: 'ITAÚ', lengths: 7 },
  { id: '54', name: 'BBVA', lengths: 9 },
  { id: '55', name: 'SANTANDER', lengths: 12 },
  { id: '56', name: 'BANDES', lengths: 9 },
  { id: '57', name: 'BANCO NACIÓN AR', lengths: 12 },
  { id: '58', name: 'HSBC', lengths: 10 },
  { id: '59', name: 'BANCO PROVINCIA AR', lengths: null },
  { id: '60', name: 'HERITAGE', lengths: 9 },
  { id: '61', name: 'PREX', lengths: null },
  { id: '62', name: 'BHU', lengths: 12 },
];

export const bankAccountTypes: AccountType[] = [
  { name: 'Cuenta Corriente', value: BankAccountTypesEnum.CUENTA_CORRIENTE },
  { name: 'Caja de Ahorro', value: BankAccountTypesEnum.CAJA_DE_AHORRO },
];

export const terminalNetworkOptions: InfoBlockField[] = [
  { name: 'New Age Data (NAD)', value: TerminalTypesEnum.NAD },
  { name: 'POS2000', value: TerminalTypesEnum.POS2000 },
  { name: 'Scanntech', value: TerminalTypesEnum.SCANNTECH },
  { name: 'Resonance', value: TerminalTypesEnum.RESONANCE },
  { name: 'Handy', value: TerminalTypesEnum.HANDY },
  { name: 'Sisteco', value: TerminalTypesEnum.SISTECO },
  { name: 'Geocom', value: TerminalTypesEnum.GEOCOM },
  { name: 'Pranasys', value: TerminalTypesEnum.PRANASYS },
  { name: 'Red Fiserv', value: TerminalTypesEnum.FISERV },
  { name: 'Red Oca', value: TerminalTypesEnum.OCA },
  { name: 'Red Visanet', value: TerminalTypesEnum.VISANET },
];

export const laws: InfoBlockField[] = [
  { name: 'Ley 19.210', value: LawsEnum.law_19210.toString() },
  { name: 'Ley 17.934 (Restaurantes)', value: LawsEnum.law_17934.toString() },
  { name: 'Ley 18.083 (IMESI)', value: LawsEnum.law_18083.toString() },
  { name: 'Ley 18.910 (AFAM)', value: LawsEnum.law_18910.toString() },
];

export const lawsFormFields: LawFormField[] = [
  { label: laws[0].name, fieldname: 'law_19210', value: LawsEnum.law_19210 },
  { label: laws[1].name, fieldname: 'law_17934', value: LawsEnum.law_17934 },
  { label: laws[2].name, fieldname: 'law_18083', value: LawsEnum.law_18083 },
  { label: laws[3].name, fieldname: 'law_18910', value: LawsEnum.law_18910 },
];

export const basicInfoFields: string[] = [
  'Nº de RUT',
  'Nombre fantasía',
  'Razón social',
  'Nombre y apellidos de contacto',
  'Teléfono',
  'Celular',
  'Email',
  'Facturación promedio mensual ($UY)',
];

export const branchFields = [
  'Nombre Fantasía',
  'Departamento',
  'Localidad',
  'Barrio',
  'Dirección',
  'Apto',
  'Solar',
  'Manzana',
];

export const beneficiaryFields = [
  'Tipo de documento',
  'Nº de Documento',
  'Nombre y apellido',
  '% de participación',
  'Es PEP',
  'Es Familiar',
];

export const mainAddressFields: string[] = [
  'Departamento',
  'Localidad',
  'Barrio',
  'Dirección',
  'Apto',
  'Solar',
  'Manzana',
];

export const bankAccountFields: string[] = [
  'Banco',
  'Nº de cuenta',
  'Moneda',
  'Nº de sucursal',
  'Titular de la cuenta',
];

export const salesOperationFields: InfoBlockField[] = [{ name: 'Venta con tarjeta presente', value: '-' }];

export const economicActivityFields: string = 'Rubro';
export const businessPlansFields: string = 'Moneda';
export const tributaryInfoFields: string = 'Tipo de contribuyente';

export const documentTypes: InfoBlockField[] = [
  { name: 'Cédula de Identidad', value: DocumentTypeEnum.CI },
  { name: 'Pasaporte', value: DocumentTypeEnum.PASSPORT },
];

export const currencies: InfoBlockField[] = [
  { name: 'PESOS URUGUAYOS', value: CurrencyEnum.URUGUAYAN_PESOS },
  { name: 'DÓLAR ESTADOUNIDENSE', value: CurrencyEnum.US_DOLLAR },
];

export const newBeneficiary: NewBeneficiaryValues = {
  documentType: null,
  documentNumber: '',
  firstName: '',
  lastName: '',
  participationPercentage: null,
  branchOfficeId: null,
  isPep: false,
  isRelative: false,
  backDocument: null,
  frontDocument: null,
  beneficiary_branch_office: null,
};

export const newBranch: NewBranchValues = {
  fantasyName: '',
  state: undefined,
  city: undefined,
  neighborhood: undefined,
  addressStreet: '',
  addressNumber: undefined,
  addressFlatNumber: undefined,
  addressSolar: undefined,
  addressPlot: undefined,
};

export const newTerminal: NewTerminalValues = { type: '', number: null };

export const COMMENT_MAX_LENGTH: number = 250;
