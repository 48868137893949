import { Fragment } from 'react';
import imageLogo from '../../assets/file-image-solid.svg';
import pdfLogo from '../../assets/file-pdf-solid.svg';
import { getFilenameFromUrl } from '../../utils/helpers';
import { FinalStepAttachments, NewBeneficiaryValues } from '../../types/add_commerce.types';

interface AttachmentsProps {
  attachments: FinalStepAttachments;
}

function Attachments({ attachments }: AttachmentsProps) {
  const previewFile = (fileUrl: string) => (fileUrl?.slice(-3) === 'pdf' ? pdfLogo : imageLogo);

  return (
    <div className="confirmation-card">
      <p className="confirmation-title">DOCUMENTOS ADJUNTOS</p>
      <div className="confirmation-info">
        <div className="info-fieldname">Formulario DGI</div>
        <div className="info files">
          <img src={previewFile(attachments.cardFormDGI)} alt="card form DGI" />
          <p>{getFilenameFromUrl(attachments.cardFormDGI)}</p>
        </div>
      </div>
      {attachments.beneficiaries.map((beneficiary: NewBeneficiaryValues, index: number) => (
        <Fragment key={index}>
          <div className="confirmation-info">
            <div>
              <div className="info-fieldname">Frente de Documento de identidad</div>
              <div className="confirmation-beneficiary-name">
                <p>
                  {beneficiary.firstName} {beneficiary.lastName}
                </p>
              </div>
            </div>
            {beneficiary.frontDocument && (
              <div className="info files">
                <img src={previewFile(beneficiary.frontDocument)} alt="front document" />
                <p>{getFilenameFromUrl(beneficiary.frontDocument)}</p>
              </div>
            )}
          </div>
          <div className="confirmation-info">
            <div>
              <div className="info-fieldname">Dorso de Documento de identidad</div>
              <div className="confirmation-beneficiary-name">
                <p>
                  {beneficiary.firstName} {beneficiary.lastName}
                </p>
              </div>
            </div>
            {beneficiary.backDocument && (
              <div className="info files">
                <img src={previewFile(beneficiary.backDocument)} alt="back document" />
                <p>{getFilenameFromUrl(beneficiary.backDocument)}</p>
              </div>
            )}
          </div>
        </Fragment>
      ))}
      {attachments.bankStatement && (
        <div className="confirmation-info">
          <div className="info-fieldname">Estado de cuenta de tarjeta</div>
          <div className="info files">
            <img src={previewFile(attachments.bankStatement)} alt="bank statement" />
            <p>{getFilenameFromUrl(attachments.bankStatement)}</p>
          </div>
        </div>
      )}
      {attachments.extraDocuments.map((document: string, index: number) => {
        return (
          document && (
            <div key={index} className="confirmation-info">
              <div className="info-fieldname">Documento extra {index + 1}</div>
              <div className="info files">
                <img src={previewFile(document)} alt="bank statement" />
                <p>{getFilenameFromUrl(document)}</p>
              </div>
            </div>
          )
        );
      })}
    </div>
  );
}

export default Attachments;
