import { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Row } from 'react-bootstrap';
import Input from '../../../components/Input/Input';
import Select from '../../../components/Input/Select';
import schemas from '../../../utils/schemas';
import { getStates, getCities, getNeighborhoods } from '../../../services/commerce.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { NewBranchValues, State, City, Neighborhood } from '../../../types/add_commerce.types';

interface NewBranchProps {
  innerRef: any;
  submitBranch(
    values: NewBranchValues,
    index: number,
    state: State | undefined,
    city: City | undefined,
    neighborhood: Neighborhood | undefined,
  ): void;
  index: number;
  branchData: NewBranchValues;
  handleDelete: (index: number) => void;
}

function NewBranch({ innerRef, submitBranch, index, branchData, handleDelete }: NewBranchProps) {
  const [states, setStates] = useState<Array<State>>([]);
  const [cities, setCities] = useState<Array<City>>([]);
  const [neighborhoods, setNeighborhoods] = useState<Array<Neighborhood>>([]);
  const [chosenState, setChosenState] = useState<State | undefined>(branchData?.neighborhood?.city?.state);
  const [chosenCity, setChosenCity] = useState<City | undefined>(branchData?.neighborhood?.city);
  const [chosenNeighborhood, setChosenNeighborhood] = useState<Neighborhood | undefined>(
    branchData?.neighborhood,
  );

  const fetchStates = async () => {
    const responseStates = await getStates();
    setStates(responseStates);
  };

  const fetchCities = async (state: State | undefined) => {
    if (state) {
      const responseCities = await getCities(state.id);
      return setCities(responseCities.data);
    }
  };

  const fetchNeighborhoods = async (city: City | undefined) => {
    if (city) {
      const responseNeighborhoods = await getNeighborhoods(city.id);
      setNeighborhoods(responseNeighborhoods.data);
    }
  };

  const handleOnChangeState = (
    targetValue: string,
    setFieldValue: (name: string, value: string | number) => void,
  ) => {
    setFieldValue('state', +targetValue);
    setFieldValue('city', '');
    setFieldValue('neighborhood', '');
    const chosenState = states.find((state: State) => state?.id === +targetValue);
    setChosenState(chosenState || undefined);
    setChosenCity(undefined);
    setChosenNeighborhood(undefined);
  };

  const handleOnChangeCities = (
    targetValue: string,
    setFieldValue: (name: string, value: string | number) => void,
  ) => {
    setFieldValue('city', +targetValue);
    setFieldValue('neighborhood', '');
    const chosenCity = cities.find((city: City) => city?.id === +targetValue);
    setChosenCity(chosenCity || undefined);
    setChosenNeighborhood(undefined);
  };

  const handleOnChangeNeighborhood = (
    targetValue: string,
    setFieldValue: (name: string, value: string | number) => void,
  ) => {
    setFieldValue('neighborhood', +targetValue);
    const chosenNeighborhood = neighborhoods.find(
      (neighborhood: Neighborhood) => neighborhood?.id === +targetValue,
    );
    setChosenNeighborhood(chosenNeighborhood || undefined);
  };

  useEffect(() => {
    fetchStates();
    fetchCities(chosenState);
    fetchNeighborhoods(chosenCity);
    branchData?.state && fetchCities(branchData?.state as State);
    branchData?.city && fetchNeighborhoods(branchData?.city as City);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchData?.state, branchData?.city]);

  useEffect(() => {
    chosenState && fetchCities(chosenState);
  }, [chosenState]);

  useEffect(() => {
    chosenCity && fetchNeighborhoods(chosenCity);
  }, [chosenCity]);

  return (
    <Formik
      innerRef={innerRef}
      initialValues={{
        fantasyName: branchData?.fantasyName!,
        state: branchData?.neighborhood?.city?.state!,
        city: branchData?.neighborhood?.city!,
        neighborhood: branchData?.neighborhood!,
        addressStreet: branchData?.addressStreet!,
        addressNumber: branchData?.addressNumber!,
        addressFlatNumber: branchData?.addressFlatNumber!,
        addressSolar: branchData?.addressSolar!,
        addressPlot: branchData?.addressPlot!,
      }}
      validationSchema={schemas.ActivateCommerceSecondStep}
      onSubmit={(values: NewBranchValues) =>
        submitBranch(values, index, chosenState, chosenCity, chosenNeighborhood)
      }
      validateOnMount
    >
      <Form className="form-row">
        <div className="title-row">
          <p className="input-subtitle">SUCURSAL {index + 1}</p>
          {index > 0 && (
            <FontAwesomeIcon className="faTrashCan" icon={faTrashCan} onClick={() => handleDelete(index)} />
          )}
        </div>
        <Row>
          <Input
            className="input col-md-6"
            type="text"
            name="fantasyName"
            label="Nombre Fantasía"
            required
            index={index}
          />
          <Select
            name="state"
            handleOnChange={handleOnChangeState}
            className="col-md-6"
            label="Departamento"
            required
            index={index}
          >
            {states.map((state: State, index: number) => (
              <option value={state?.id} key={`${state?.id}_${index}`}>
                {state?.name}
              </option>
            ))}
          </Select>
          <Select
            name="city"
            handleOnChange={handleOnChangeCities}
            className="col-md-6"
            label="Localidad"
            required
            index={index}
          >
            {cities.map((city: City, index: number) => (
              <option value={city?.id} key={`${city?.id}_${index}`}>
                {city?.name}
              </option>
            ))}
          </Select>
          <Select
            name="neighborhood"
            handleOnChange={handleOnChangeNeighborhood}
            className="col-md-6"
            label="Barrio"
            required
            index={index}
          >
            {neighborhoods.map((neighborhood: Neighborhood, index: number) => (
              <option value={neighborhood?.id} key={`${neighborhood?.id}_${index}`}>
                {neighborhood?.name}
              </option>
            ))}
          </Select>
          <Input
            className="input col-md-6"
            type="text"
            name="addressStreet"
            label="Calle"
            required
            index={index}
          />
          <Input
            className="input col-md-6"
            type="text"
            name="addressNumber"
            label="Nº de puerta"
            required
            index={index}
          />
          <Input
            className="input col-md-4"
            type="number"
            name="addressFlatNumber"
            label="Apto (opcional)"
            index={index}
          />
          <Input
            className="input col-md-4"
            type="number"
            name="addressSolar"
            label="Solar (opcional)"
            index={index}
          />
          <Input
            className="input col-md-4"
            type="number"
            name="addressPlot"
            label="Manzana (opcional)"
            index={index}
          />
        </Row>
      </Form>
    </Formik>
  );
}

export default NewBranch;
