import { FormStepEnum } from '../types/enums/form-step.enum';

interface BusinessPage {
  step: FormStepEnum;
  path: string;
}

const publicPages = ['/activar-cuenta', '/ingresar', '/recuperar-password', '/resultado'];

const isPublicPage = (page: string): boolean => {
  return publicPages.some((p) => page.includes(p));
};

export const navigationUtils = { isPublicPage };

export const addBusinessPages: BusinessPage[] = [
  {
    step: FormStepEnum.FIRST,
    path: '/informacion-comercio',
  },
  {
    step: FormStepEnum.SECOND,
    path: '/sucursal',
  },
  {
    step: FormStepEnum.THIRD,
    path: '/beneficiario-final',
  },
  {
    step: FormStepEnum.FOURTH,
    path: '/domicilio-cuenta',
  },
  {
    step: FormStepEnum.FIFTH,
    path: '/rubro-tributo-terminal',
  },
  {
    step: FormStepEnum.SIXTH,
    path: '/adjuntar-documentos',
  },
  {
    step: FormStepEnum.SEVENTH,
    path: '/confirmacion',
  },
];

export const getAddBusinessPage = (step: FormStepEnum) => {
  if (step === FormStepEnum.NONE || step === FormStepEnum.DONE) {
    return null;
  }
  const path = addBusinessPages.find((businessPage: BusinessPage) => businessPage.step === step)?.path;
  return path;
};
