import { useEffect, useState } from 'react';
import { Col, Container, ProgressBar, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import reconciliation from '../../../assets/Reconciliation.png';
import BusinessCommentForm from '../../../components/BusinessInfo/BusinessCommentForm';
import BusinessInfo from '../../../components/BusinessInfo/BusinessInfo';
import NavigationFooter from '../../../components/NavigationFooter/NavigationFooter';
import { confirmFinishBusiness } from '../../../services/commerce.service';
import { AddCommerceSixthAndFinalStepLocationState } from '../../../types/add_commerce.types';
import { ConfirmBusinessResponse } from '../../../types/business/response';
import { triggerToasts } from '../../../utils/helpers';
import { COMMENT_MAX_LENGTH } from '../../../utils/data';
import AddCommerceSuccessModal from './AddCommerceSuccessModal';

function AddCommerceFirstStep() {
  const location = useLocation();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [surchargedFee, setSurchargedFee] = useState<boolean>(false);
  const [anticipatePayments, setAnticipatePayments] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');
  const localState: AddCommerceSixthAndFinalStepLocationState =
    location.state as AddCommerceSixthAndFinalStepLocationState;

  useEffect(() => {
    setSurchargedFee(localState.surchargedFee || false);
    setComment(localState.comment || '');
    setAnticipatePayments(localState.anticipatePayments || false);
  }, [localState.surchargedFee, localState.comment, localState.anticipatePayments]);

  const handleGoBack = () => navigate('/adjuntar-documentos', { state: { ...localState } });

  const handleSubmit = async () => {
    if (comment.length > COMMENT_MAX_LENGTH) {
      return triggerToasts(`El comentario no puede tener mas de ${COMMENT_MAX_LENGTH} caracteres`);
    }

    setIsLoading(true);
    const response: ConfirmBusinessResponse = await confirmFinishBusiness(
      localState.id!,
      comment,
      surchargedFee,
      anticipatePayments,
    );
    setIsLoading(false);
    if (response.result) {
      return setShowModal(true);
    }
    triggerToasts(response);
  };

  const toggleModal = () => setShowModal(!showModal);

  return (
    <>
      <AddCommerceSuccessModal show={showModal} toggleModal={toggleModal} />
      <Container className="confirmation">
        <ProgressBar now={100} />
        <Row>
          <Col lg={12}>
            <h1>
              <img src={reconciliation} alt="Comercio" className="me-3" /> Confirmación y firma del contrato
            </h1>
            <BusinessInfo
              businessData={localState}
              showAttachments
              setCheckboxState={setSurchargedFee}
              checkboxState={surchargedFee}
              setAnticipatePayments={setAnticipatePayments}
              anticipatePayments={anticipatePayments}
            />
          </Col>
        </Row>
        <BusinessCommentForm setCommentFunction={setComment} commentValue={comment} />
      </Container>
      <NavigationFooter previousHandler={handleGoBack} nextHandler={handleSubmit} loading={isLoading} />
    </>
  );
}

export default AddCommerceFirstStep;
