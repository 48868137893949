import { Fragment, Dispatch, SetStateAction } from 'react';
import { BusinessTableChunk, BusinessTableRow } from '../../types/add_commerce.types';
import PaymentsOptions from './PaymentsOptions';

interface BusinessTableProps {
  headings: string[];
  tableData: BusinessTableChunk[];
  tableDataError: string;
  setCheckboxState?: Dispatch<SetStateAction<boolean>>;
  checkboxState?: boolean;
  setAnticipatePayments?: Dispatch<SetStateAction<boolean>>;
  anticipatePayments?: boolean;
  comment?: string;
}

function BusinessTable({
  headings,
  tableData,
  tableDataError,
  setCheckboxState,
  checkboxState,
  setAnticipatePayments,
  anticipatePayments,
  comment,
}: BusinessTableProps) {
  return tableData.length && !tableDataError ? (
    <>
      {tableData.map((tableChunk: BusinessTableChunk) => (
        <Fragment key={tableChunk.category}>
          <div className="table-category">{tableChunk.category}</div>
          <div className="table-title">
            {headings.map((heading: string, index: number) => (
              <div key={`${heading}_${index}`}>{heading}</div>
            ))}
          </div>
          {tableChunk.rows.map((row: BusinessTableRow, index: number) => (
            <div className="table-description" key={`${row.plan}_${index}`}>
              <div>{row.plan}</div>
              <div>{row.currency}</div>
              <div>{row.fees}</div>
              <div>{row.deferred}</div>
            </div>
          ))}
        </Fragment>
      ))}
      <PaymentsOptions
        setAnticipatePayments={setAnticipatePayments}
        anticipatePayments={anticipatePayments}
        setCheckboxState={setCheckboxState}
        checkboxState={checkboxState}
        comment={comment}
      />
    </>
  ) : (
    <>
      <h4 className="confirmation-title">{tableDataError}</h4>
      <PaymentsOptions
        setAnticipatePayments={setAnticipatePayments}
        anticipatePayments={anticipatePayments}
        setCheckboxState={setCheckboxState}
        checkboxState={checkboxState}
        comment={comment}
      />
    </>
  );
}

export default BusinessTable;
