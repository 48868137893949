import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import management from '../../assets/Consumption-management.svg';
import BusinessInfo from '../../components/BusinessInfo/BusinessInfo';
import Input from '../../components/Input/Input';
import NavigationFooter from '../../components/NavigationFooter/NavigationFooter';
import { getCommerce, updateBusinessExecutive } from '../../services/commerce.service';
import { AddCommerceSixthAndFinalStepLocationState } from '../../types/add_commerce.types';
import { FormStatusEnum } from '../../types/enums/form-status.enum';
import { FormStepEnum } from '../../types/enums/form-step.enum';
import { getAddBusinessPage } from '../../utils/navigationUtils';
import schemas from '../../utils/schemas';

interface SearchCommerceValues {
  rut: number | null;
}

interface CommerceStatus {
  state: FormStatusEnum;
  step: FormStepEnum;
}

function SearchCommerce() {
  const [commerceExists, setCommerceExists] = useState<boolean | null>(null);
  const [commerceMessage, setCommerceMessage] = useState<string>('');
  const [commerceRut, setCommerceRut] = useState<number>(0);
  const [businessData, setBusinessData] = useState<AddCommerceSixthAndFinalStepLocationState | any>({});
  const [commerceStatus, setCommerceStatus] = useState<CommerceStatus | null>(null);
  const [commerceId, setCommerceId] = useState<number>(0);
  const navigate = useNavigate();

  const submitSearchCommerce = async (values: SearchCommerceValues) => {
    const getCommerceResponse = await getCommerce(Number(values.rut));

    if (getCommerceResponse.status !== 401) {
      setCommerceRut(Number(values.rut));

      if (getCommerceResponse.data) {
        setCommerceMessage(getCommerceResponse.data.message);
        setCommerceExists(getCommerceResponse.data.rutExists);
        setCommerceStatus({ state: getCommerceResponse.data.state, step: getCommerceResponse.data.step });
        setBusinessData(getCommerceResponse?.data?.businessData || {});
        setCommerceId(+getCommerceResponse.data.businessData.id);
      }
    } else {
      localStorage.removeItem('fullName');
    }
  };

  const handleNext = async () => {
    const path = getAddBusinessPage(commerceStatus?.step!);

    if (commerceStatus?.state === FormStatusEnum.FREE) {
      await updateBusinessExecutive(+commerceId);
    }

    if (path) {
      setCommerceStatus(null);
      navigate(path, { state: { commerceRut, ...businessData } });
    }
  };

  const showFooter =
    (commerceExists && commerceStatus?.state === FormStatusEnum.IN_PROCESS) ||
    (!commerceExists && commerceStatus?.state === FormStatusEnum.EMPTY) ||
    (commerceExists && commerceStatus?.state === FormStatusEnum.FREE);

  return (
    <>
      <Container className="search-commerce">
        <Row>
          <Col sm={12}>
            <h1 className="mb-5">
              <img src={management} alt="Comercio" className="me-3" />
              Búsqueda de comercio
            </h1>
            <Formik
              initialValues={{ rut: null }}
              validationSchema={schemas.SearchCommerceSchema}
              onSubmit={submitSearchCommerce}
            >
              <Form className="row align-items-end">
                <Input
                  type="tel"
                  name="rut"
                  placeholder="RUT"
                  className="flex-grow-1 col-9 col-md-10 position-relative pb-3"
                  label="Ingrese número de RUT"
                />
                <Col sm={3} md={2}>
                  <Button
                    type="submit"
                    variant="outline-danger"
                    className="primary-outlined-button search-button"
                  >
                    Buscar
                  </Button>
                </Col>
              </Form>
            </Formik>
            <p className="search-description">{commerceMessage}</p>
            {!!Object.keys(businessData).length && commerceStatus?.step === FormStepEnum.DONE && (
              <div className="confirmation">
                <BusinessInfo
                  businessData={businessData as AddCommerceSixthAndFinalStepLocationState}
                  showAttachments
                  checkboxState={businessData.surchargedFee}
                  anticipatePayments={businessData.anticipatePayments}
                  comment={businessData.comment}
                />
              </div>
            )}
          </Col>
        </Row>
      </Container>
      {showFooter && (
        <NavigationFooter nextHandler={handleNext} nextText={commerceExists ? 'Continuar' : 'Dar de alta'} />
      )}
    </>
  );
}

export default SearchCommerce;
