import { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Col, Container, ProgressBar, Row } from 'react-bootstrap';
import activeCommerceLogo from '../../../assets/icon-empresa-active.png';
import Input from '../../../components/Input/Input';
import NavigationFooter from '../../../components/NavigationFooter/NavigationFooter';
import schemas from '../../../utils/schemas';
import { createCommerce } from '../../../services/commerce.service';
import { triggerToasts } from '../../../utils/helpers';
import { CreateBusinessRequest } from '../../../types/business/request';
import {
  AddCommerceFirstStepValues,
  AddCommerceFirstStepLocationState,
} from '../../../types/add_commerce.types';

function AddCommerceFirstStep() {
  const location = useLocation();
  const navigate = useNavigate();
  const formRef = useRef<any>();
  const localState: AddCommerceFirstStepLocationState = location.state as AddCommerceFirstStepLocationState;

  const submitActivateCommerceFirstStep = async (values: AddCommerceFirstStepValues) => {
    const requestBody: CreateBusinessRequest = {
      rut: values.rut!.toString(),
      fantasyName: values.fantasyName,
      legalName: values.socialReason,
      contactFirstName: values.contactFirstName,
      contactLastName: values.contactLastName,
      phone: values.phone,
      cellphone: values.cellphone,
      email: values.email,
      averageMonthlyBilling: values.averageMonthlyBilling!,
    };

    const response = await createCommerce(requestBody);

    if (response.result && response.data) {
      const businessData = response.data.businessData || response.data;
      return navigate('/sucursal', { state: { ...localState, ...businessData } });
    }
    triggerToasts(response);
  };

  const handleSubmit = () => formRef?.current?.handleSubmit();

  const handleGoBack = () => navigate('/buscar-comercio');

  return (
    <>
      <Container className="first-step">
        <ProgressBar now={100 / 7} />
        <Row>
          <Col lg={12}>
            <h1>
              <img src={activeCommerceLogo} alt="Comercio" className="me-3" /> Información básica del comercio
            </h1>
            <Formik
              innerRef={(formElement: any) => (formRef.current = formElement)}
              onSubmit={submitActivateCommerceFirstStep}
              initialValues={{
                rut: localState?.rut || localState?.commerceRut,
                fantasyName: localState?.fantasyName,
                socialReason: localState?.socialReason,
                contactFirstName: localState?.contactFirstName,
                contactLastName: localState?.contactLastName,
                phone: localState?.phone!,
                cellphone: localState?.cellphone!,
                email: localState?.email,
                averageMonthlyBilling: localState?.averageMonthlyBilling!,
              }}
              validationSchema={schemas.ActivateCommerceFirstStep}
            >
              <Form>
                <Row>
                  <Input className="input col-md-6" type="number" name="rut" label="Número de RUT" readOnly />
                  <Input
                    className="input col-md-6"
                    type="text"
                    name="fantasyName"
                    label="Nombre fantasía"
                    required
                  />
                  <Input
                    className="input col-md-6"
                    type="text"
                    name="contactFirstName"
                    label="Nombre del contacto"
                    required
                  />
                  <Input
                    className="input col-md-6"
                    type="text"
                    name="contactLastName"
                    label="Apellido del contacto"
                    required
                  />
                  <Input
                    className="input col-md-6"
                    type="text"
                    name="socialReason"
                    label="Razón social"
                    required
                  />
                  <Input className="input col-md-6" type="tel" name="phone" label="Teléfono" required />
                  <Input className="input col-md-6" type="tel" name="cellphone" label="Celular" required />
                  <Input className="input col-md-6" type="email" name="email" label="Email" required />
                  <Input
                    className="input col-md-6"
                    type="number"
                    name="averageMonthlyBilling"
                    label="Facturación promedio mensual ($UY) (opcional)"
                  />
                </Row>
              </Form>
            </Formik>
          </Col>
        </Row>
      </Container>
      <NavigationFooter previousHandler={handleGoBack} nextHandler={handleSubmit} />
    </>
  );
}

export default AddCommerceFirstStep;
