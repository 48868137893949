import { Button, Card, Modal, Row, Col, Container } from 'react-bootstrap';
import CardHeader from 'react-bootstrap/esm/CardHeader';
import { AddCommerceFourthStepValues } from '../../../types/add_commerce.types';
import { banks, Bank, bankAccountTypes, currencies } from '../../../utils/data';

interface ConfirmBankProps {
  show: boolean;
  toggleModal: () => void;
  values: AddCommerceFourthStepValues;
  handleNextStep: (values: AddCommerceFourthStepValues) => void;
}

const ConfirmBankModal = ({ show, toggleModal, handleNextStep, values }: ConfirmBankProps) => {
  const { accountNumber, accountBranch, accountHolder, accountCurrency, accountType } = values;
  const accountTypeName = bankAccountTypes.find((type) => type.value === Number(accountType))?.name;
  const accountCurrencyName = currencies.find((currency) => currency.value === Number(accountCurrency))?.name;

  return (
    <Modal show={show} onHide={toggleModal} centered>
      <Card className="modal-card confirm-bank-card">
        <CardHeader>
          <h1>Confirmación de datos de cuenta bancaria</h1>
        </CardHeader>
        <Card.Body>
          <Container className="card-red">
            <Row>
              <Col>
                <p>Banco</p>
              </Col>
              <Col>
                <p>{banks.find((bank: Bank) => bank.id === values.bankId)?.name}</p>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <p>Cuenta N°</p>
                <p>Tipo de cuenta</p>
                <p>Moneda</p>
                <p>Sucursal</p>
              </Col>
              <Col>
                <p>{accountNumber}</p>
                <p>{accountTypeName}</p>
                <p>{accountCurrencyName}</p>
                <p>{accountBranch || '-'}</p>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <p>Titular</p>
              </Col>
              <Col>
                <p>{accountHolder}</p>
              </Col>
            </Row>
          </Container>
          <Button className="primary-outlined-button w-100" onClick={() => handleNextStep(values)}>
            Los datos son correctos &gt;
          </Button>
          <Button className="primary-outlined-button w-100" onClick={toggleModal}>
            Volver
          </Button>
        </Card.Body>
      </Card>
    </Modal>
  );
};

export default ConfirmBankModal;
