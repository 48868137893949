import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Formik, Form, Field } from 'formik';
import { Button, Col, Container, ProgressBar, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import schemas from '../../../utils/schemas';
import { triggerToasts, sortArrayAlphabetically } from '../../../utils/helpers';
import { doRefsSubmissions } from '../../../utils/formikHelpers';
import { lawsFormFields, LawFormField, newTerminal } from '../../../utils/data';
import Select from '../../../components/Input/Select';
import NavigationFooter from '../../../components/NavigationFooter/NavigationFooter';
import InvoicingLogo from '../../../assets/Invoicing.svg';
import POSLogo from '../../../assets/POS.svg';
import CorporatesLogo from '../../../assets/Corporates.svg';
import { TerminalTypesEnum } from '../../../types/enums/terminal-types.enum';
import { UpdateBusinessTerminalRequest } from '../../../types/business/request';
import {
  updatePageFive,
  deleteTerminal,
  getBusinessCategories,
  getTaxPayers,
} from '../../../services/commerce.service';
import {
  AddCommerceFifthStepLocationState,
  ActivateCommerceFifthStepValues,
  BusinessCategory,
  TaxPayer,
  NewTerminalValues,
} from '../../../types/add_commerce.types';
import NewTerminal from './NewTerminal';

function AddCommerceFifthStep() {
  const location = useLocation();
  const navigate = useNavigate();
  const formRefs = useRef<any>([]);
  const localState: AddCommerceFifthStepLocationState = location.state as AddCommerceFifthStepLocationState;
  const [businessCategories, setBusinessCategories] = useState<Array<BusinessCategory>>([]);
  const [taxPayers, setTaxPayers] = useState<Array<TaxPayer>>([]);
  const [terminals, setTerminals] = useState<Array<NewTerminalValues>>(
    localState.terminals?.length ? localState.terminals : [{ ...newTerminal, localId: uuidv4() }],
  );
  const [submittedTerminals, setSubmittedTerminals] = useState<Array<NewTerminalValues>>([]);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<ActivateCommerceFifthStepValues>({
    businessCategoryId: '',
    taxpayerTypeId: '',
    law_19210: false,
    law_17934: false,
    law_18083: false,
    law_18910: false,
  });

  const fetchBusinessCategories = async () => {
    const responseBusinessCategories = await getBusinessCategories();
    if (Array.isArray(responseBusinessCategories.data)) {
      const sortedResponse = sortArrayAlphabetically(responseBusinessCategories.data, 'name');
      setBusinessCategories(sortedResponse);
    }
  };

  const fetchTaxPayers = async () => {
    const responseTaxPayers = await getTaxPayers();
    if (Array.isArray(responseTaxPayers.data)) {
      const sortedResponse = sortArrayAlphabetically(responseTaxPayers.data, 'description');
      setTaxPayers(sortedResponse);
    }
  };

  useEffect(() => {
    fetchBusinessCategories();
    fetchTaxPayers();
  }, []);

  const postBusinessLawsAndTerminals = async () => {
    const businessLawsAndTerminals: UpdateBusinessTerminalRequest = {
      businessCategoryId: formValues.businessCategoryId,
      taxpayerTypeId: formValues.taxpayerTypeId,
      law_19210: formValues.law_19210,
      law_17934: formValues.law_17934,
      law_18083: formValues.law_18083,
      law_18910: formValues.law_18910,
      terminals: submittedTerminals.map((terminal) => ({
        terminalType:
          typeof terminal.type === 'string'
            ? (+terminal.type as TerminalTypesEnum)
            : (terminal.type as TerminalTypesEnum),
        terminalNumber: terminal.number?.toString()!,
      })),
    };

    const response = await updatePageFive(localState.id!, businessLawsAndTerminals);

    if (response.result && response.data) {
      const businessData = response.data.businessData || response.data;
      return navigate('/adjuntar-documentos', { state: { ...localState, ...businessData } });
    }
    triggerToasts(response);
  };

  useEffect(() => {
    if (submitted && submittedTerminals.length === terminals.length && Object.keys(formValues).length) {
      postBusinessLawsAndTerminals();
      setSubmitted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [terminals, submittedTerminals, localState, navigate, formValues]);

  const handleGoBack = () => navigate('/domicilio-cuenta', { state: { ...localState } });

  const handleSubmit = () => {
    if (formRefs.current) {
      const didFormRefsSubmitted: boolean = doRefsSubmissions(formRefs);
      didFormRefsSubmitted && setSubmitted(true);
    }
  };

  const handleFirstForm = (values: ActivateCommerceFifthStepValues) => setFormValues(values);

  const submitTerminal = (values: NewTerminalValues, index: number) => {
    const updateArray = [...submittedTerminals];
    updateArray[index] = values;
    setSubmittedTerminals(updateArray);
  };

  const handleAddNewTerminal = () => setTerminals([...terminals, { ...newTerminal, localId: uuidv4() }]);

  const handleDeleteTerminal = async (index: number) => {
    const terminalToDelete = terminals.find(
      (branch: NewTerminalValues, filterIndex: number) => index === filterIndex,
    );
    if (terminalToDelete?.id) {
      const response = await deleteTerminal(localState.id!, terminalToDelete?.id);

      if (response.result) {
        return setTerminals(response.data.terminals);
      }
      return triggerToasts(response);
    }
    setTerminals((prevState) =>
      prevState.filter((terminal: NewTerminalValues, filterIndex: number) => index !== filterIndex),
    );
  };

  return (
    <>
      <Container className="fifth-step">
        <ProgressBar now={(100 / 7) * 5} />
        <Row>
          <Col lg={12}>
            <Formik
              innerRef={(formElement: any) => (formRefs.current[0] = formElement)}
              initialValues={{
                businessCategoryId: localState?.businessCategoryId!,
                taxpayerTypeId: localState?.taxpayerTypeId!,
                law_19210: localState.law_19210,
                law_17934: localState.law_17934,
                law_18083: localState.law_18083,
                law_18910: localState.law_18910,
              }}
              validationSchema={schemas.ActivateCommerceFifthStep}
              onSubmit={handleFirstForm}
            >
              <Form>
                <Row className="row-first">
                  <h1 className="form-title">
                    <img src={CorporatesLogo} alt="Comercio" className="me-3" /> Rubro
                  </h1>
                  <Select className="col-12" name="businessCategoryId" label="Rubro" required>
                    {businessCategories?.map((category: BusinessCategory) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </Select>
                </Row>
                <Row className="form-row">
                  <h1 className="form-title">
                    <img src={InvoicingLogo} alt="Comercio" className="me-3" /> Información tributaria
                  </h1>
                  <Select className="col-12" name="taxpayerTypeId" label="Tipo de contribuyente" required>
                    {taxPayers?.map((taxPayer: TaxPayer) => (
                      <option key={taxPayer.id} value={taxPayer.id}>
                        {taxPayer.description}
                      </option>
                    ))}
                  </Select>
                </Row>
                <Row className="form-row-laws">
                  <p className="input-title">(Seleccionar en caso de que corresponda)</p>
                  {lawsFormFields.map((law: LawFormField, index: number) => (
                    <Col key={index}>
                      <label className="checkbox-label">
                        <Field className="checkbox" type="checkbox" name={law.fieldname} />
                        <span>{law.label}</span>
                      </label>
                    </Col>
                  ))}
                </Row>
              </Form>
            </Formik>
            <Row className="form-row">
              <h1 className="form-title">
                <img src={POSLogo} alt="Comercio" className="me-3" /> Red de terminales
              </h1>
              {terminals.map((terminal: NewTerminalValues, index: number) => (
                <NewTerminal
                  innerRef={(formElement: any) => (formRefs.current[index + 1] = formElement)}
                  submitTerminal={submitTerminal}
                  index={index}
                  key={terminal.localId || `${terminal.type}_${index}`}
                  terminalData={terminal}
                  handleDelete={handleDeleteTerminal}
                />
              ))}
            </Row>
          </Col>
        </Row>
        <div>
          <Button onClick={handleAddNewTerminal} className="add-section">
            <FontAwesomeIcon icon={faPlus} />
            Agregar Terminal
          </Button>
        </div>
      </Container>
      <NavigationFooter previousHandler={handleGoBack} nextHandler={handleSubmit} />
    </>
  );
}

export default AddCommerceFifthStep;
