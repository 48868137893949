import {
  Beneficiary,
  CreateBusinessRequest,
  OfficeBranch,
  UpdateBusinessAccountAddressRequest,
  UpdateBusinessBeneficiariesRequest,
  UpdateBusinessBranchRequest,
  UpdateBusinessTerminalRequest,
  UpdateFilesRequest,
} from '../types/business/request';
import { getParsedFormData } from '../utils/serviceHelpers';
import { dataToUpperCase } from '../utils/helpers';
import axios from './axios';

export async function confirmFinishBusiness(
  businessId: number,
  comment: string,
  surchargedFee: boolean,
  anticipatePayments: boolean,
) {
  try {
    const headers = { headers: { businessId: businessId.toString() } };
    const res = await axios.put(`/business/confirm`, { comment, surchargedFee, anticipatePayments }, headers);
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function updatePageSix(businessId: number, updateFilesRequest: UpdateFilesRequest) {
  try {
    const formData = getParsedFormData(updateFilesRequest);

    const headers = {
      headers: { businessId: businessId.toString(), 'Content-Type': 'multipart/form-data' },
    };
    const res = await axios.post(`/business/updatepagesix`, formData, headers);
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function updatePageFive(
  businessId: number,
  updateBusinessTerminalRequest: UpdateBusinessTerminalRequest,
) {
  try {
    const headers = { headers: { businessId: businessId.toString() } };
    const res = await axios.post(`/business/updatepagefive`, updateBusinessTerminalRequest, headers);
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function updatePageFour(
  businessId: number,
  updateBusinessAccountAddressRequest: UpdateBusinessAccountAddressRequest,
) {
  try {
    const headers = { headers: { businessId: businessId.toString() } };
    updateBusinessAccountAddressRequest = dataToUpperCase(updateBusinessAccountAddressRequest);
    const res = await axios.post(`/business/updatepagefour`, updateBusinessAccountAddressRequest, headers);
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function updatePageThree(
  businessId: number,
  updateBusinessBeneficiariesRequest: UpdateBusinessBeneficiariesRequest,
) {
  try {
    const headers = { headers: { businessId: businessId.toString() } };
    updateBusinessBeneficiariesRequest.beneficiaries.map((beneficiary: Beneficiary) =>
      dataToUpperCase(beneficiary),
    );
    const res = await axios.post(`/business/updatepagethree`, updateBusinessBeneficiariesRequest, headers);
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function updatePageTwo(
  businessId: number,
  updateBusinessBranchRequest: UpdateBusinessBranchRequest,
) {
  try {
    const headers = { headers: { businessId: businessId.toString() } };
    updateBusinessBranchRequest.officesBranch.map((branch: OfficeBranch) => dataToUpperCase(branch));
    const res = await axios.post(`/business/updatepagetwo`, updateBusinessBranchRequest, headers);
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function createCommerce(createBusinessRequest: CreateBusinessRequest) {
  try {
    createBusinessRequest = dataToUpperCase(createBusinessRequest);
    const res = await axios.post(`/business/create`, createBusinessRequest);
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function getCommerce(rut: number) {
  try {
    const headers = { headers: { rut: rut.toString() } };
    const res = await axios.get(`/business`, headers);
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function getStates() {
  try {
    const res = await axios.get('/location/states');
    return res.data.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function getCities(stateId: number) {
  try {
    const res = await axios.get(`/location/cities?stateId=${stateId}`);
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function getNeighborhoods(cityId: number) {
  try {
    const res = await axios.get(`/location/neighborhoods?cityId=${cityId}`);
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function getBusinessCategories() {
  try {
    const res = await axios.get('/business-categories');
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function getTaxPayers() {
  try {
    const res = await axios.get('/tax-payer');
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function getBusinessPlansTableData(taxpayerType: string, businessSector: string) {
  const headers = { headers: { taxpayerType, businessSector } };
  try {
    const res = await axios.get('/tax-payer/table', headers);
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function deleteBusinessBranch(businessId: number, branchId: number) {
  try {
    const headers = {
      headers: { businessId: businessId.toString(), branchId: branchId.toString() },
    };

    const res = await axios.delete('business/branch', headers);
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function deleteBeneficiary(businessId: number, branchId: number, beneficiaryId: number) {
  try {
    const headers = {
      headers: {
        businessId: businessId.toString(),
        branchId: branchId.toString(),
        beneficiaryId: beneficiaryId.toString(),
      },
    };

    const res = await axios.delete('business/beneficiary', headers);
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function deleteTerminal(businessId: number, terminalId: number) {
  try {
    const headers = {
      headers: { businessId: businessId.toString(), terminalId: terminalId.toString() },
    };

    const res = await axios.delete('business/terminal', headers);
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function updateBusinessExecutive(businessId: number) {
  try {
    await axios.put(`/business/${businessId}/executive`);

    return true;
  } catch (err: any) {
    return err.response;
  }
}
